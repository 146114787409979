
var util = UIkit.util;

// $('html, body').css('overflow-y', 'hidden');
const video = document.querySelector('.hero_video');
const streamaxTitle = util.$('.streamax__title');
const empower = document.querySelector('.empower');
let vh = window.innerHeight;

$(document).ready(function(){
  $(this).scrollTop(0);
});

function mapRange (number, inMin, inMax, outMin, outMax) {
  return (number - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
}


function animateValue(id, start, end, duration) {
  if (start === end) return;
  var range = end - start;
  var current = start;
  var increment = end > start? 1 : -1;
  var stepTime = Math.abs(Math.floor(duration / range));
  var obj = document.getElementById(id);
  var timer = setInterval(function() {
      current += increment;
      obj.innerHTML = current;
      if (current == end) {
          clearInterval(timer);
          $('.preloader').fadeOut(550);
          $('.fadein').fadeIn(750);
          $('.hero .text__layer').addClass('active');
          $('html, body').css('overflow-y', 'auto');
      }
  }, stepTime);
}

if ( document.getElementById('value')) {
  animateValue("value", 0, 100, 30000);
} 

if( video ) {
  var r = new XMLHttpRequest();
  r.onload = function() {
      var currentValue = parseInt($('#value').text());
      video.src = URL.createObjectURL(r.response);
      video.muted = true;
      animateValue("value", currentValue, 100, 3000);
      video.play();
      video.loop = true;
  };
  if (video.canPlayType('video/mp4;codecs="avc1.42E01E, mp4a.40.2"')) {
      r.open("GET", "assets/img/video.mp4");
  }
  
  r.responseType = "blob";
  r.send();
}

if ( empower ) {
  $('.tagline__content .t100').css('opacity', '0');
  let elements = document.querySelectorAll('.tagline__content .t100');
  const peak = vh / 2;
  const bottomLine = peak - vh * 0.4;
  const topLine = peak + vh * 0.4;

  window.onscroll = function() {
  for ( var i = 0; i < elements.length; i++ ) {
    let eh = elements[i].getBoundingClientRect().height;
    
      let eot = elements[i].getBoundingClientRect().top + eh * 0.5;
      if ( eot < peak ) {
        elements[i].style.opacity = mapRange(eot, bottomLine, peak, 0, 1.5)
      }
      if ( eot > peak ) {
        elements[i].style.opacity = mapRange(eot, topLine, peak, 0, 1.5)
      }
      
    }
  } 
}

// const body = document.body;
// const stream = document.querySelector('#stream');
// const streamDot = document.querySelector('#stream_dot');

// if ( stream && streamDot ) {

//   window.addEventListener('scroll', function() {
//     let streamBottomPos = stream.getBoundingClientRect().bottom;
//     let streamDotContainer = document.querySelector('.stream_dot_container');
//     let viewportHeight = window.innerHeight;

//     if ( streamBottomPos >= viewportHeight * 0.5 ) {
//       streamDotContainer.style.backgroundColor = 'blue';
//     } else {
//       streamDotContainer.style.backgroundColor = 'pink';
//     }
//   });
// }